@import 'vendor/bootstrap/bootstrap';
@import '~uikit/src/scss/variables';
@import '~uikit/src/scss/uikit';
@import 'vendor/fontawesome/fontawesome';
@import 'vendor/slick-slider/slick',
      'vendor/slick-slider/slick-theme';

@import "variables/sizes",
        "variables/colors",
        "variables/fonts";

@import "global/global",
        "global/form/checkbox",
        "global/form/slider";
@import "layout/header",
        "layout/nav",
        "layout/footer";

@import "pages/home",
        "pages/product",
        "pages/about",
        "products/categories",
        "products/product-list";

@import 'checkout/cart';
@import 'dirty';

.alert {
    margin-bottom: 0;
}

.pastel-3-border-bottom {
    border-bottom: 1px solid $pastel-3-accent;
}

.toggle-search i {
    color: $main-color;
    @include media-breakpoint-down(md) {
        font-size: 20px;
    }
}

.account-menu {

    position: relative;
    i {
        color: $main-color;
        @include media-breakpoint-down(md) {
            font-size: 20px;
        }
    }
    .submenu {

        position: absolute;
        display: block;
        top: 0;
        right: 0;
        width: 180px;
        opacity: 0;
        pointer-events: none;
        transition: all 300ms ease;
        list-style-type: none;
        padding-left: 0;
        padding-top: 10px;
        z-index: 10;

        li {

            a {
                background-color: $white;
                display: block;
                padding: 3px 15px;
                color: $black;
                &:hover {
                    color: $main-color;
                }
            }

        }

    }
    &:hover {
        .submenu {
            opacity: 1;
            pointer-events: all;
        }
    }

}
