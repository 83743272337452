
:root {
    --brown: #cab087;
    --beige: #bd9c69;
    --grey: #9c9389;
    --dark-brown: #c3ae9c;
    --bebas: bebas-neue, sans-serif;
    --lato: Lato, sans-serif;
}

html, body {
    overflow-x: hidden;
}

body, em {
    color: var(--grey);
}



header > div > div {
    justify-content: center;
    -webkit-justify-content: center;
}

.menu-container {
    /*flex: 0 0 100%;*/
    max-width: 100%;
    text-align: center;
}

.menu-cart {text-align: right;}

.menu-main-menu {list-style: none;margin-top: 0px;margin-bottom: 0px;padding-left: 0px;}
.menu-main-menu li {display: inline-block;padding-left: 10px;padding-right: 10px;}
.menu-main-menu li a {
    font-family: var(--lato);
    font-size: 16px;
    line-height: 20px;
    color: var(--beige);
    text-transform: uppercase;
}

.menu-main-menu li a:hover,
.menu-main-menu li a:focus {
    color: var(--grey);
    text-decoration: none;
}

.top-bar {
    background-color: var(--brown);
    padding-top: 15px;
    padding-bottom: 15px;
}

.top-bar img {
    width: 160px;
    /*height: 70px;*/
}

.page-home .top-bar {display: none;}


.category-header {
    background-color: transparent;
    background: url('/images/category-header-img.jpg') no-repeat 50% 50%;
    background-size: cover;
    position: relative;
    z-index: 0;
}

.about-header {
    background-color: transparent;
    background: url('/images/over-ons-header.jpg') no-repeat 50% 50%;
    background-size: cover;
    position: relative;
    z-index: 0;
}

.category-header::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('/images/category-header-img-overlay.png') no-repeat 50% 50%;
    background-size: cover;
    z-index: 1;
    width: 100%;
    height: 100%;
}

.category-header > div {
    position: relative;
    z-index: 2;
}

.category-header h1 {
    color: #FFF;
}

.categorie-header-webshop {
    font-family: var(--bebas);
    font-size: 60px;
    line-height: 1;
    color: #FFF;
    display: inline-block;
    padding-top: 80px;
    padding-bottom: 80px;
}

.categorie-header-usp {
    background-color: rgba(255, 255, 255, 0.702);
    color: #443627;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    padding-top: 12px;
    padding-bottom: 12px;
}

.category-filter .offset-2 {
    margin-left: 15px !important;
}

.category-filter .offset-2 a, a.category-filter-item-sub {
    font-family: var(--lato);
    font-size: 16px;
    line-height: 25px;
    color: var(--grey) !important;
}

a.category-filter-item-sub.active {
    color: var(--beige) !important;
}

.category-filter .offset-2 a:hover,
.category-filter .offset-2 a:focus {
    color: var(--beige) !important;
}

body > div:nth-child(2) > section.category-detail.p-2.p-md-5.pb-5 > div > div > div.col-md-4.col-xl-2.d-none.d-md-block > div:nth-child(2) > div > h4  {
    margin-left: 15px;
    margin-right: 15px;
}

.category-filter h4, .categorypricetitle h4 {
    display: block;
    width: 100%;
    border-bottom: 1px solid var(--beige);
}

.category-filter-item, .category-filter h4, .categorypricetitle h4 {
    font-family: var(--bebas);
    font-size: 24px;
    color: var(--beige) !important;
}

.category-filter h4 i, .categorypricetitle h4 i {float: right;width: 13px;}

.category-filter svg {width: 13px;}

body > div:nth-child(2) > section.category-detail.p-2.p-md-5.pb-5 > div > div > div.col-md-4.col-xl-2.d-none.d-md-block > div:nth-child(2) > div > h4 > svg,
body > div:nth-child(2) > section.category-detail.p-2.p-md-5.pb-5 > div > div > div.col-md-4.col-xl-2.d-none.d-md-block > div:nth-child(3) > div.row.categorypricetitle > div > h4 > svg {
    width: 13px;
}

.category-filter .price-input input {
    background: #f9f5f1;
    border: 1px solid #f9f5f1;
    color: var(--grey);
    font-family: var(--lato);
    font-size: 16px;
}

.category-filter .price-input:before {
    color: #9c9389 !important;
    z-index: 1;
}

.ui-slider.ui-slider-horizontal .ui-widget-header {
    background: var(--beige);
}

.ui-slider.ui-slider-horizontal .ui-slider-handle {
    background: var(--beige);
    border-color: var(--beige);
}

.ui-slider.ui-slider-horizontal .ui-slider-range, .ui-slider.ui-slider-horizontal.ui-widget-content {
    border: 1px solid var(--beige);
}

.category-detail {
    padding-top: 70px !important;
}

.category-detail #order {
    border: 1px solid #f9f5f1;
    background: #f9f5f1;
    color: #9c9389;
    /*max-width: 195px;*/
    /*    margin-left: auto;
        margin-right: 0;
        display: block;*/
    position: relative;
    top: -1px;
    padding-left: 10px;
}

.category-detail .product-description {
    font-size: 14px;
    font-style: italic;
    text-align: center;
}

.action-price {
    text-align: center;
}

.cattabfilters {text-align: right;}

.cattabfilters .form-group {display: inline-block;margin-left: 15px;}

.cattabfilters select {height: 40px;}

.catipp {
    border: 1px solid #f9f5f1;
    background: #f9f5f1;
    color: #9c9389;
    /*max-width: 250px;*/
    margin-left: auto;
    margin-right: 0;
    display: block;
    height: 40px;
    /*padding-left: 10px;
    padding-right: 20px;*/
    padding-left: 10px;
}

.shopping-bag-icon {
    width:16px;
    height: 20px;
    filter: invert(77%) sepia(11%) saturate(1304%) hue-rotate(357deg) brightness(83%) contrast(84%);
    margin-right: 10px;
    position: relative;
    top: -2px;
}

.shopping-bag-icon:hover,
.shopping-bag-icon:focus {
    filter: invert(79%) sepia(11%) saturate(948%) hue-rotate(358deg) brightness(91%) contrast(87%);
}


.pager {
    background: #f9f5f1;
    padding: 10px 15px;
    box-sizing: border-box;
}

.pager .page-btn a {
    font-size: 16px;
    color: #9c9389;
}

.pager .page-btn a:hover, .pager .page-btn a:focus {
    color: var(--beige);
    text-decoration: none;
}

.pager .prev-btn a, .pager .next-btn a {
    font-size: 16px;
    color: #9c9389;
}

.pager .prev-btn a:hover, .pager .next-btn a:hover,
.pager .prev-btn a:focus, .pager .next-btn a:focus {
    color: var(--beige);
    text-decoration: none;
}


.orderblock {position: relative;}
.orderblock svg {position:absolute;top: 8px;right: 15px;font-size: 22px;}

.product-list-item {border: none;}
.product-list-item:before {transition: none;}
.product-list-item:hover {background-color: transparent;}
.product-list-item .image-container {height: 270px !important;padding-bottom: inherit;}
.product-list-item .new-label {display: none;}
.product-list-item .product-info:before, .product-list-item .product-info:after {display: none;}
.product-list-item:hover:before, .product-list-item:hover:after {border: none;transition: none;}
.product-list-item .product-info .product-title {
    color: var(--grey);
    font-size: 15.3px;
    line-height: 19.13px;
    text-align: center;
}
.product-list-item .product-info .product-price {
    color: var(--beige);
    font-family: var(--bebas);
    font-size: 22.95px;
    font-weight: 400;
    text-align: center;
}

#product-nav > div > div  {padding-top: 70px !important;padding-bottom: 0px !important;}
#product-detail > div > div {padding-top: 0px !important;}

#product-detail .product-title {
    font-family: var(--brown);
    font-size: 30px;
    line-height: 40px;
    text-transform: uppercase;
}


#product-detail .product-detail-price {
    color: var(--grey);
}

#product-detail .productdesc {padding-left: 0px;padding-right: 0px;}


#product-detail .order-btn,
#product-detail .proceed-button a {
    background-color: transparent;
    border: 2px solid var(--brown);
    border-radius: 0px;
    color: var(--beige);
    font-size: 16px;
    text-transform: uppercase;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
}

#product-detail .order-btn:hover,
#product-detail .proceed-button a:hover,
#product-detail .order-btn:focus,
#product-detail .proceed-button a:focus {
    background-color: var(--brown);
    color: #FFF;
}

#product-detail .order-btn:hover .shopping-bag-icon,
#product-detail .proceed-button a:hover .shopping-bag-icon,
#product-detail .order-btn:focus .shopping-bag-icon,
#product-detail .proceed-button a:focus .shopping-bag-icon {
    filter: brightness(0) invert(1);
}

#product-detail .qty-field {
    background-color: #f9f5f1;
    border: 1px solid #f9f5f1;
    border-radius: 0px;
    color: var(--grey);
}

#product-detail .qty-group .qty-down {
    bottom: 8px;
}

#product-detail .qty-group .qty-down {
    bottom: 8px;
}


#product-detail #lightgallery {
    border: 1px solid var(--beige);
    height: auto;
    min-height: 500px;
}

#product-detail #lightgallery img,
#product-detail #lightgallery .main-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    /*object-fit: cover;*/
}

.followus {
    margin-left: 0px;
    margin-right: 0px;
}

.followus {
    font-size: 16px;
    line-height: 28px;
    color: var(--grey);
    font-style: italic;
    max-width: 485px;
    margin-bottom: 0px !important;
}

.followus p strong {
    font-style: normal;
}

#related-products {
    background-color: var(--beige) !important;
}

#product-detail + #related-products  {padding-bottom: 20px !important;}

#related-products h2 {
    font-family: var(--bebas);
    font-size: 50px;
    text-transform: uppercase;
    color: #FFF;
}

#related-products hr {
    border-top: 5px solid #FFF;
    border-bottom: 0px;
    margin-left: auto;
    margin-right: auto;
    max-width: 282px;
    position: relative;
    left: -141px;
}

#related-products .product-list-item {
    background-color: transparent;
}

#related-products .product-list-item .image-container img {

}

#related-products .product-list-item .image-container {
    width: 235px;
    height: 235px !important;
    padding-bottom: inherit;
    margin-left: auto;
    margin-right: auto;
}

#related-products .product-list-item .product-info {
    padding-bottom: 0px !important;
}


#related-products .product-list-item .product-info .product-title  {
    background-color: transparent;
    font-size: 15.3px;
    line-height: 19.13px;
    color: #FFF;
}

#related-products .product-description {
    font-size: 14px;
    font-style: italic;
    text-align: center;
    color: #FFF;
}

#related-products .product-price {
    background-color: transparent;
    font-family: var(--bebas);
    font-size: 22.95px;
    color: #FFF;
}


#related-products > div.container {max-width: 1080px;}

#related-products .related-products {padding-top: 0px !important;padding-bottom: 0px !important;}

#related-products > div > div > div > section > div > div > div > div:nth-child(5) {}

#related-products .related-products > div > div:nth-child(n+5) {
    display:none;
}

.back-to-webshop {display: none;}

.contact-main .form-group {
    margin-bottom: 20px !important;
}

.contact-main .contact-form input,
.contact-main .contact-form textarea {
    border: 1px solid var(--beige);
    color: #000;
}

.contact-main .contact-form input:focus,
.contact-main .contact-form textarea:focus {
    box-shadow: none !important;
}

.contact-main .contact-form input:not([type="checkbox"]) {
    height: 40px !important;
}

.contact-main .contact-form .privacy-notice-paragraph {
    font-size: 12px;
    line-height: 18px;
}

.contact-main .contact-form .required-privacy-notice {
    padding: 8px;
    border: 1px solid var(--beige);
}

.contact-main .contact-form .required-privacy-notice::after {
    font-size: 16px !important;
    top: -8px !important;
    left: 3px !important;
}

.contact-main .contact-form .privacy-notice-paragraph::before {
    display: none;
}

.contact-main .contact-form .privacy-notice-paragraph a {
    font-size: 12px;
    line-height: 18px;
    color: var(--beige);
}

.contact-main .contact-form .privacy-notice-paragraph a:hover,
.contact-main .contact-form .privacy-notice-paragraph a:focus {
    color: var(--brown);
    text-decoration: none;
}

.contact-main .contact-form .info-opt-in-checkbox {
    padding: 8px;
    border: 1px solid var(--beige);
}

.contact-main .contact-form .info-opt-in-checkbox:checked:after {
    font-size: 16px;
    top: -8px;
    left: 3px;
}

.contact-main .contact-form .info-opt-in-checkbox + label::before {
    display: none;
}


.contact-main .contact-data a {
    color: var(--beige);
}

.contact-main .submit {
    background-color: var(--beige);
    border: 1px solid var(--beige);
    color: #fff;
    padding: 10px 40px;
    border-radius: 0;
    text-transform: uppercase;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    margin-top: 20px;
}

.contact-main .submit:hover,
.contact-main .submit:focus {
    background-color: var(--brown);
    border: 1px solid var(--brown);
}

.contact-main .contact-data a:hover,
.contact-main .contact-data a:focus {
    color: var(--brown);
    text-decoration: none;
}




.contact-main .contactmap {margin-top: 40px;}






.letsgetsocial h3 {
    padding-left: 80px;
    margin-bottom: 10px;
}

.letsgetsocial hr {
    border-top: 4px solid var(--beige);
    border-bottom: none;
    max-width: 280px;
    margin-top: 10px;
    margin-bottom: 40px;
}

.letsgetsocial ul {
    list-style: none;
    padding-left: 80px;
}

.letsgetsocial ul li {display: inline-block;margin-right: 10px;}

.letsgetsocial ul li a {
    border: 2px solid var(--beige);
    color: var(--beige);
    border-radius: 50%;
    padding: 10px;
    width: 49px;
    height: 49px;
    display: inline-block;
    box-sizing: border-box;
    text-align: center;
    font-size: 24px;
    line-height: 24px;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
}

.letsgetsocial ul li a:hover,
.letsgetsocial ul li a:focus {
    background-color: var(--beige);
    color: #FFF;
}

.contact-main > div.container, .disclaimer-main >div.container, .algemene-voorwaarden-main >div.container, .privacy-policy-main >div.container {
    padding-top: 70px;
    padding-bottom: 70px;
}

.disclaimer-main .row, .algemene-voorwaarden-main .row, .privacy-policy-main .row {
    margin-left: 0px;
    margin-right: 0px;
}


.privacyverklaring-main-content-table {overflow-x: auto;}
.privacyverklaring-main-content-table table {border-collapse: collapse;}
.privacyverklaring-main-content-table table tr {border-bottom: 1px solid var(--beige);}
.privacyverklaring-main-content-table table tr th,
.privacyverklaring-main-content-table table tr td {
    text-align: left;
    padding: 5px 15px 5px 5px;
}

.footercontact {
    border-right: 4px solid var(--beige);
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    justify-content: center;
    -webkit-justify-content: center;
    flex-direction: column;
    -webkit-flex-direction: column;
}

.footerlinks {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    justify-content: center;
    -webkit-justify-content: center;
    flex-direction: column;
    -webkit-flex-direction: column;
}

.footercontact, .footerlinks {
    color: var(--beige);
    font-size: 16px;
    line-height: 23.50px;
}

.footercontact p:first-child {margin-bottom: 10px;}
.footercontact p:last-child {margin-top: 10px;margin-bottom: 0px;}
.footercontact strong {text-transform: uppercase;}
.footercontact a, .footerlinks a {color: var(--beige);display: inline-block;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
}
.footercontact a:hover, .footercontact a:focus, .footerlinks a:hover, .footerlinks a:focus {text-decoration: none;color: var(--brown);}





.category-filter-mobile {
    /*margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 40px;*/
    background-color: var(--white);
    padding: 5px 10px;
    border: 1px solid #f9f5f1;
    background: #f9f5f1;
    color: #9c9389;
    height: 40px;
    display: inline-block;
}

.category-filter-mobile > div {border: none !important;}


.filter-col.open #category-sub-7 {
    margin-left: 30px;
}

.filter-col {
    opacity: 0;
    position: fixed;
    top: 0px;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: #fff;
    pointer-events: none;
    transform: translateX(-100%);
    padding-top: 60px;
    transition: all 300ms ease;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: -1;
    box-shadow: 7px 0 4px rgb(0 0 0 / 5%);
}

.filter-col.open {
    /*z-index: 999999;*/
    z-index: 999999999;
    opacity: 1;
    transform: translateX(0);
    pointer-events: all;
}

.filter-col .uk-hidden {
    display: block !important;
}

.filter-col .close-filter {
    position: absolute;
    right: 15px;
    top: 15px;
    color: var(--main-color);
    font-size: 25px;
}

.filter-col.open .row{
    margin-left: 0px;
    margin-right: 0px;
}

.product-nav-1 {
    color: var(--grey);
    font-weight: 900;
}

.product-nav-2 {
    color: var(--grey);
    font-weight: 900;
}


.product-nav-1:hover, .product-nav-1:focus,
.product-nav-2:hover, .product-nav-2:focus {
    color: var(--beige);
    text-decoration: none;
}

.slick-list > div > div:nth-child(1) {
    margin-top: 0px !important;
}



#creatieve-zussen {padding-top: 100px;padding-bottom: 100px;}
#creatieve-zussen h2 {position: relative;margin-bottom: 90px;}
#creatieve-zussen h2::before {
    position: absolute;
    content: "";
    width: 280px;
    height: 5px;
    background-color: #bd9c69;
    bottom: -33px;
    left: -80px;
}

#creatieve-zussen p {
    max-width: 420px;
}



#ontdek-onze-shop {padding-top: 100px;}

#ontdek-onze-shop > div.container {

    background-image: linear-gradient(to right, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0.73)), url("/images/ontdek-onze-shop-bg.jpg");
    background-size: cover;
    background-position: center;
    padding: 150px 120px 100px 40px;
}

#ontdek-onze-shop > div > div > div > div {max-width: 420px;margin-left: auto;}

#ontdek-onze-shop h2 {
    ont-family: var(--bebas);
    font-size: 50px;
    line-height: 1.2;
    text-transform: uppercase;
    color: var(--white);
    position: relative;
    margin-bottom: 90px;
}

#ontdek-onze-shop h2::before {
    position: absolute;
    content: "";
    width: 280px;
    height: 5px;
    background-color: #fff;
    bottom: -33px;
    left: -80px;
}

#ontdek-onze-shop p {
    color: var(--white);
}


#product-detail .productvariants {
    padding-left: 0px;padding-right:0px;
    margin-top: 25px;
}


.alert-success {
    color: #fff;
    background-color: var(--beige);
    border-color: var(--beige);
}

.alert-success .btn-outline-success {
    color: #fff;
    background-color: var(--brown);
    border-color: var(--brown);
}


.alert-success .btn-outline-success:hover,
.alert-success .btn-outline-success:focus {
    color: var(--brown);
    background-color: #FFF;
    border-color: #FFF;
    box-shadow: none;
}

.alert-success .btn-outline-success:not(:disabled):not(.disabled):active,
.alert-success .btn-outline-success:not(:disabled):not(.disabled).active,
.alert-success .btn-outline-success.dropdown-toggle {
    color: var(--brown);
    background-color: #FFF;
    border-color: #FFF;
    box-shadow: none;
}


.form-steps li {
    border: 1px solid var(--beige);
}

.form-steps li a, .form-steps li span {
    color: var(--beige);
}

.form-steps li.enabled, .form-steps li.current {
    background-color: var(--beige);
    border: 1px solid var(--beige);
}

.cart-contents .qty-selector .qty-field {
    border: 1px solid var(--beige);
}

.cart-contents .qty-selector .qty-select-open {
    border: 1px solid var(--beige);
    background-color: var(--beige);
}

.cart-contents .qty-selector .qty-select-options {
    border: 1px solid var(--beige);
}

.cart-contents .qty-selector .qty-select-options div:hover {
    background-color:var(--beige);
    color: #FFF;
}

.cart-contents .qty-selector .qty-select-options::-webkit-scrollbar-thumb {
    background-color:var(--beige);
}

input:not([type="checkbox"]):not([type="radio"]) {
    border: 1px solid var(--beige);
}

.form-control {
    border: 1px solid var(--beige);
}

#conditions + label a {
    color: var(--beige);
}

#conditions + label a:hover,
#conditions + label a:focus {
    color: var(--brown);
    text-decoration: none;
}

input, textarea {-webkit-appearance: none;}
input:focus, textarea:focus {
    box-shadow: none !important;
}


.form-control:focus {
    border-color: var(--beige) !important;
}

/*.category-detail .image-container {position: relative;}
.category-detail .image-container .imagethumbs {
    position: absolute;
    bottom: 15px;
    left: 15px;
}

.category-detail .image-container .imagethumbs img {
    width: 50px;
    height: 50px;
    object-fit: contain;
}

*/


.category-detail .catprodblock {position: relative;}

.imagethumbs {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 99;
}

.imagethumbs img {
    width: 50px;
    height: 50px;
    object-fit: contain;
    margin: 2.5px 5px;
    border: 1px solid var(--beige);
}

.iti--separate-dial-code, .country-select {width: 100%;}

.checkout-header > div {
    padding-top: 80px;
    padding-bottom: 60px;
}

.home-onze-blogs {
    background-color: var(--dark-brown);
    padding-top: 90px;
    padding-bottom: 50px;
}

.blogs-main {
    padding-top: 90px;
    padding-bottom: 70px;
}

.home-onze-blogs  .blog-overview h2 {
    font-family: var(--bebas);
    font-size: 32.61px;
    color: #FFF;
    position: relative;
    left: 40px;
    margin-bottom: 52px;
}

.blog-overview .btn {
    margin-top: 20px;
}

.home-onze-blogs  .blog-overview h2::after {

    position: absolute;
    content: "";
    width: 280px;
    height: 5px;
    background-color: #fff;
    bottom: -33px;
    left: -40px;

}

.home-onze-blogs  .blog-overview .creatic-blog-article-item-wrapper:hover h2 {
    color: rgba(255, 255, 255, 0.75);
}

.home-onze-blogs  .blog-overview .creatic-blog-article-item-wrapper:hover h2::after {
    background-color: rgba(255, 255, 255, 0.75);
}




.blogs-main  .blog-overview h2 {
    font-family: var(--bebas);
    font-size: 32.61px;
    color: var(--beige);
    position: relative;
    left: 40px;
    margin-bottom: 83px;
}

.blogs-main  .blog-overview h2::after {

    position: absolute;
    content: "";
    width: 280px;
    height: 5px;
    background-color: var(--beige);
    bottom: -33px;
    left: -40px;

}

.blogs-main  .blog-overview .intro {
    color: var(--dark-brown);
}

.blogs-main .blog-overview .creatic-blog-article-item-wrapper:hover h2 {
    color: rgba(189, 156 , 105, 0.75);
}

.blogs-main .blog-overview .creatic-blog-article-item-wrapper:hover h2::after  {
    background-color: rgba(189, 156 , 105, 0.75);
}

.blog-overview .creatic-blog-article-item-wrapper:hover h2,
.blog-overview .creatic-blog-article-item-wrapper:focus h2 {
    text-decoration: none;
}

.home-onze-blogs a:hover,
.home-onze-blogs .uk-link:hover,
.home-onze-blogs .uk-link-toggle:hover .uk-link,
.home-onze-blogs .uk-link-toggle:focus .uk-link {
    text-decoration: none;
}


.blogs-main a:hover,
.blogs-main .uk-link:hover,
.blogs-main .uk-link-toggle:hover .uk-link,
.blogs-main .uk-link-toggle:focus .uk-link {
    text-decoration: none;
}

.home-onze-blogs .creatic-blog-articles {
    position: relative;
}
/*
.home-onze-blogs .creatic-blog-articles::before {
    content: 'Onze blogs';
    position: absolute;
    top: 50%;
    right: 0%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 1;
    font-family: var(--bebas);
    font-size: 139.66px;
    line-height: 126.55px;
    color: #FFF;
    max-width: 300px;
}*/


.blog-detail {
    padding: 70px 0px;
}

.blog-detail .blog-content {
    padding-bottom: 40px;
}

.blog-detail .blog-link-overview {
    background-color: rgba(189, 156, 105, 0.8);
    color: #fff;
    padding: 10px 15px;
    border-radius: 0;
    text-transform: uppercase;
    display: inline-block;
}

.blog-detail .blog-link-overview:hover,
.blog-detail .blog-link-overview:focus {
    background-color: rgba(255, 255, 255, 0.8);
    color: #bd9c69;
    text-decoration: none;
}

.blog-detail .prev-link-wrapper a,
.blog-detail .next-link-wrapper a {
    background-color: rgba(189, 156, 105, 0.8);
    color: #fff;
    padding: 10px 40px;
    border-radius: 0;
    text-transform: uppercase;
    display: inline-block;
}

.blog-detail .prev-link-wrapper a:hover,
.blog-detail .next-link-wrapper a:hover {
    background-color: rgba(255, 255, 255, 0.8);
    color: #bd9c69;
    text-decoration: none;
}


.home-onze-blogs-main-hidden {display: none !important;}

.home-onze-blogs-main-right {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}



.home-onze-blogs-main-right a {
    font-family: var(--bebas);
    font-size: 139.66px;
    line-height: 126.55px;
    color: #FFF;
    max-width: 300px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.home-onze-blogs-main-right a:hover,
.home-onze-blogs-main-right a:focus {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.75);
}

.home-category-menu > div:nth-child(1) {
    display: none;
}

.home-categories-slider-inner {}
.home-categories-slider-inner .image-container {
    position: relative;
    height: 100%;
    max-height: 430px;
}

.home-categories-slider-inner .image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.home-categories-slider-inner .image-container .title {
    color: #fff !important;
    font-family: "Bebas Neue", sans-serif;
    font-size: 38px;
    text-shadow: -1px -1px 0px rgb(150 150 150 / 94%);
    padding-bottom: 40px;
    /*position: absolute;
    bottom: 0;
    left: 0;
    right: 0;*/
    text-align: center;
}

.home-categories-slider-inner .image-container .prodimg {
    padding-top: 330px;
    border: 1px solid #bd9c69;
}


.home-categories-slider-inner .image-container .productlink {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
}

.home-product-slider-nav a {
    background: #bd9c69;
    color: #fff;
}

.home-product-slider-nav a:hover,
.home-product-slider-nav a:focus {
    background: #9c9389;
    color: #fff;
}

.checkout-page, .registerform {
    .invalid-feedback {
        display:block !important;
        list-style: none;
        padding-left: 0;
    }
}

.form-group {
    .registerlink {
        display: block;
        color: var(--beige);
        margin-top: 30px;
    }

    .loginlink {
        display: block;
        color: var(--beige);
    }
}

.pager .disabled {
    display: none;
}


@media (min-width: 1200px) {
    #product-detail .product-images {padding-right: 40px;}
    #product-detail .product-content {padding-left: 40px;}

    .contact-main .contact-form {padding-right:  40px;}
    .contact-main .contact-data {padding-left: 40px;}

}


@media (max-width: 1199px) {
    .disclaimer-main h1,
    .algemene-voorwaarden-main h1,
    .privacy-policy-main h1 {
        font-size: 36px;
    }

    .disclaimer-main h2,
    .algemene-voorwaarden-main h2,
    .privacy-policy-main h2 {
        font-size: 32px;
    }

    .disclaimer-main h3,
    .algemene-voorwaarden-main h3,
    .privacy-policy-main h3 {
        font-size: 28px;
    }

    .page-home > div:nth-child(1) > header > div > div > div.col-6.col-md-3.d-md-block {display: none !important;}
    /*.page-home header .menu-container {order: 11;-webkit-order: 11;

        flex: 0 0 90%;
        max-width: 90%;
        text-align: center !important;

    }

    .page-home > div:nth-child(1) > header > div > div > div.col-1.col-md-3.text-right.order-md-12 {
        flex: 0 0 10%;
        max-width: 10%;
        text-align: center !important;
    }*/



    /*    header .menu-container {order: 11;-webkit-order: 11;

            flex: 0 0 90%;
            max-width: 90%;
            text-align: center !important;

        }

        header > div > div > div.col-1.col-md-3.text-right.order-md-12 {
            flex: 0 0 10%;
            max-width: 10%;
            text-align: center !important;
        }*/


}

@media (min-width: 992px) and (max-width: 10450px) {
    .menu-main-menu li {
        display: inline-block;
        padding-left: 9px;
        padding-right: 9px;
    }

    .menu-main-menu li a {
        font-size: 15px;
        line-height: 20px;
    }

}


@media (min-width: 960px) and (max-width: 1199px) {
    .pl-5, .px-5 {
        padding-left: 30px !important;
    }
    .pr-5, .px-5 {
        padding-right: 30px !important;
    }
    .container {
        max-width: 100%;
    }

    .prodqty {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .prodbtn {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
        margin-top: 25px;
    }

}


@media (min-width: 992px) {

    .menu-burger {display: none;}

    header > div.container-fluid > div.row {
        /*  justify-content: space-around !important;
          -webkit-justify-content: space-around !important;*/
        /*flex: 0 0 auto;
        width: auto;
        max-width: 100%;*/
    }

    .home-onze-blogs .blog-overview .creatic-blog-article-item-wrapper {
        flex: 0 0 50%;
        max-width: 50%;
        padding-left: 20px;
        padding-right: 20px;
    }


    .blogs-main .blog-overview .creatic-blog-article-item-wrapper {
        flex: 0 0 33.333%;
        max-width: 33.333%;
        padding-left: 20px;
        padding-right: 20px;
    }

    .home-onze-blogs .blog-overview .creatic-blog-article-item-wrapper:nth-child(1n + 3) {display: none;}

    /*.blogs-main .blog-overview .creatic-blog-article-item-wrapper:nth-child(1n + 4) {
        margin-top: 80px;
    }*/

    .home-onze-blogs .creatic-blog-articles .creatic-blog-article img {
        min-height:  475px;
        object-fit: cover;
    }

    .home-onze-blogs .creatic-blog-articles .creatic-blog-article img + div {
        margin-top: 35px !important;
    }

}

@media (max-width: 991px) {

    .menu-cart {
        //flex: 0 0 50%;
        //max-width: 50%;
        text-align: right;
        order: inherit;
    }

    .mini-cart {padding-top: 20px;}

    .shopping-bag-icon {width: 32px;height: 20px;     top: -8px;}

    .menu-logo {display: none;}

    body > div:nth-child(2) > header > div > div > div {box-sizing:border-box;}
    body > div:nth-child(2) > header > div > div > div.col-5.d-md-none.text-right,
    body > div:nth-child(2) > header > div > div > div.col-5.d-md-none.text-right > div {
        display: block !important;
    }

    body > div:nth-child(2) > header > div > div > div.col-5.d-md-none.text-right, body > div:nth-child(2) > header > div > div > div.col-5.d-md-none.text-right > div {
        margin-left: auto;
    }

    /*    body > div:nth-child(2) > header > div > div > div.col-1 {
            flex: 0 0 8.3333333333%;
            max-width: 8.3333333333%;
        }*/

    .home-onze-blogs .blog-overview .creatic-blog-article-item-wrapper:nth-child(1n + 3) {
        display: none;
    }

    .home-onze-blogs-main-right {
        @include media-breakpoint-up(lg) {
            padding-top: 70px;
        }
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }



    #ontdek-onze-shop > div.container {
        padding: 75px 20px 50px 20px;
    }

    #creatieve-zussen h2,
    #ontdek-onze-shop h2 {
        font-size: 40px;
    }

    /* .page-home > div:nth-child(1) > header > div > div > div.col-6.col-md-3.d-md-block,
     body header > div > div > div.col-6.col-md-3.d-md-block {
         display: block !important;
         flex: 0 0 50%;
         max-width: 50%;
     }

     .page-home > div:nth-child(1) > header > div > div > div:nth-child(2),
     body header > div > div > div:nth-child(2) {
         display: block !important;
         flex: 0 0 40%;
         max-width: 40%;
     }

     .page-home > div:nth-child(1) > header > div > div > div:nth-child(2) > div,
     body header > div > div > div:nth-child(2) > div {
         display: block !important;
         margin-left: auto;
     }
 */

    .category-header .row {
        margin-left: 0px;
        margin-right: 0px;
    }

    .pl-5, .px-5 {
        padding-left: 15px !important;
    }

    .pr-5, .px-5 {
        padding-right: 15px !important;
    }

    .container {
        max-width: 100%;
    }

    .prodqty {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .prodbtn {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
        margin-top: 25px;
    }

}


@media (min-width: 768px) and (max-width: 991px) {
    .footercontact {order: 0;-webkit-order:0;width: 50%;}
    .footerlinks {order: 1;-webkit-order:1;width: 50%;}
    .letsgetsocial {order: 2;-webkit-order:2;width: 100%;text-align:center;margin-top: 70px !important;}
    .letsgetsocial h3 {padding-left: inherit;}
    .letsgetsocial hr {margin-left:auto;margin-right: auto;}
    .letsgetsocial ul {padding-left: inherit;}

    header .menu-burger {
    flex: 0 0 35%;
    max-width: 35%;
    }

    header .menu-cart {
        flex: 0 0 65%;
        max-width: 65%;
    }

}


@media (min-width: 992px) and (max-width: 1199px) {
    header .menu-cart {
        flex: 0 0 27%;
        max-width: 27%;
    }
}


@media (max-width: 767px) {

    section.home-categories {
        margin-top: 20px;
        margin-bottom: 40px;
    }

    section.home-categories .home-category-menu {
        position: relative;
        padding-top: 43px;
        padding-bottom: 43px;
    }

    .blog-detail .prev-link-wrapper {margin-bottom: 20px;}
    .blog-detail .next-link-wrapper {margin-bottom: 20px;}

    .blog-detail .panel-right {margin-top: 40px;}

    .form-steps {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
    }

    .form-steps li {
        display: block;
        text-align: center;
        margin: 10px;
        max-width: 200px;
    }

    #product-nav > div > div {
        padding-bottom: 70px !important;
    }

    .mobhide {display: none;}

    .menu-main-menu li {
        display: block;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .contact-main .contact-data {margin-top: 40px;}
    .footercontact {order: 0;-webkit-order:0;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        text-align: center;
    }
    .footerlinks {order: 1;-webkit-order:1;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        text-align: center;
        margin-top: 40px !important;
    }
    .letsgetsocial {order: 2;-webkit-order:2;text-align: center;}
    .letsgetsocial h3 {padding-left: inherit;}
    .letsgetsocial hr {margin-left:auto;margin-right: auto;}
    .letsgetsocial ul {padding-left: inherit;}

    .home-onze-blogs-main-right a {
        font-size: 109.66px;
        line-height: 96.55px;
    }
}


.logincontainer button {
    width: 100%;
    margin-bottom: 30px;
    max-width: 297px;
}

.newsletter-form {
    padding-left: 15px;
    padding-right: 15px;
}

@media (max-width: 640px) {
    .cattabfilters > div {width: 100%;margin-bottom: 25px;margin-left: 0px !important;margin-right: 0px !important;text-align: left;}
    .cattabfilters select {width: 100%;}
}

@media (max-width: 480px) {
    section.home-intro h1 {
        font-size: 38px;
    }

    .home-onze-blogs-main-right a {
        font-family: var(--bebas);
        font-size: 89.66px;
        line-height: 76.55px;
    }

}


@media (max-width: 420px) {
    .menu-burger {
        flex: 0 0 22%;
        max-width: 22%;
    }
    .menu-cart  {
        flex: 0 0 78%;
        max-width: 78%;
    }
}

.page-algemene-voorwaarden .content-div a {
    color: $main-color !important;
}



.logincontainer .butn-login,
.logincontainer .btn-link,
.logincontainer .registerlink {
    width: 100%;
    max-width: 401px;
}

.logincontainer .btn-link:hover,
.logincontainer .registerlink:hover,
.logincontainer .btn-link:focus,
.logincontainer .registerlink:focus {
    text-decoration: none;
}



.customeraccounts .select2-container--default .select2-selection--multiple .select2-selection__choice {
    color: #000 !important;
}
