section.home-intro {
    background: {
        image: url("/images/home-header.png");
        size:cover;
        position: center;
    };
    h1 {
        color: $white;
        font-size: 60px;
    }
    .intro-content {
        padding: {
            top: 200px;
            bottom: 200px;
        }
        @include media-breakpoint-down(sm) {
            padding: {
                top: 50px;
                bottom: 50px;
            }
        }
    }
}

section.home-content {
    @include media-breakpoint-up(lg) {
        margin-top: 100px;
    }
    h2 {
        position: relative;
        margin-bottom: 98px;
        &:after {
            position: absolute;
            content: "";
            width: 280px;
            height: 5px;
            background-color: $main-color;
            bottom: -33px;
            left: -80px;

        }
    }


}

#home-b1 {
    position: relative;
    padding-top: 50px;
    padding-bottom: 50px;
    @include media-breakpoint-up(lg) {
        min-height: 600px;
        .container {
            height: 100%;

            .row {
                min-height: 600px;
            }
        }
    }
    @include media-breakpoint-up(md) {
        &:before {
            position: absolute;
            top: 0;
            right: 0;
            content: "";
            width: 50%;
            height: 100%;

            background: {
                image: url('/images/home-bg-1.jpg');
                size:cover;
                position: center;
            }
        }
    }
}

section.home-categories {
    margin-top: 100px;
    .home-category-menu {
        position: relative;
        padding: {
            top: 113px;
            bottom:113px
        }
        &:before {
            position: absolute;
            content: "";
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            height: 100%;
            width: 80%;
            background-color: $main-color;
            z-index: 0;

        }
        >div {
            position: relative;
            z-index: 1;
        }
        .category-item {
            text-decoration: none !important;
            > div {
                padding-top: 330px;
                border: 1px solid $main-color
            }
            .info {
                color: $white !important;
                font: {
                    family: $h-font;
                    size: 38px
                }
                text-shadow: -1px -1px 0px rgba(150, 150, 150, 0.94);
                padding-bottom: 40px;
            }
        }
    }
}

section#home-trendy {
    .container {
        background: {
            image: linear-gradient(to right, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0.73)), url("/images/tijdloos.jpg");
            size:cover;
            position: center;
        }
        padding: 40px;

        .row {
            height: 614px;
            align-items: center;
            color: $white;
            h2 {
                color: $white;
                &:after {
                    background-color: $white;
                }
            }
        }
    }
}

#home-passion {
    position: relative;
    padding-top: 50px;
    padding-bottom: 50px;
    @include media-breakpoint-up(lg) {
        min-height: 600px;
        .container {
            height: 100%;

            .row {
                min-height: 600px;
            }
        }
    }
    @include media-breakpoint-up(md) {
        &:before {
            position: absolute;
            top: 0;
            right: 0;
            content: "";
            width: 50%;
            height: 100%;

            background: {
                image:  url("/images/passie.jpg");
                size:cover;
                position: center;
            }
        }
    }
}

.creatic-blog-article .intro {
    color: $white;
}

.newsletter-form {
    background: rgba(189, 156, 105, 1);
}

.newsletter-form * {
    color: #FFF;
}

.newsletter-form input {
    border: 1px solid #FFF !important;
    min-width: 185px;
}

.newsletter-form > .row > div:nth-child(2) {
    margin-top: 40px !important;
}

.newsletter-form .btn-subscribe {
    /*background-color: rgba(189, 156, 105, 0.8);
    border: 1px solid rgba(189, 156, 105, 0.8);*/
    background: #FFF;
    border: 1px solid #FFF;
    color:  rgba(189, 156, 105, 1);
    padding: 5px 40px;
    border-radius: 0;
    text-transform: uppercase;
    display: inline-block;
}

.newsletter-form .btn-subscribe:hover,
.newsletter-form .btn-subscribe:focus {
    text-decoration: none;
    background-color: rgba(255, 255, 255, 0.95);
    color: #bd9c69;
}

a#betaling,
a#levering,
a#retournering {
    display: block;
    position: relative;
    top: -100px;
    visibility: hidden;
}

@include media-breakpoint-down(sm) {
    .home-onze-blogs-main-right {
        justify-content: start !important;
        margin-bottom: 40px;
    }
}



/*input, textarea, select {-webkit-appearance: none;}*/
