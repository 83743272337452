


$main-font: 'Lato', sans-serif;
$bold-font:  'Lato', sans-serif;
$h-font: 'Bebas Neue', sans-serif;
$hand-font: 'Dream  Catcher Regular', serif;

.handwriting {
    font-family: $hand-font;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-search:before {
    content: "\e900";
    transform: translateY(2px);
    display: inline-block;
}
.icon-user:before {
    content: "\e901";
    transform: translateY(2px);
    display: inline-block;
}
.icon-shopping-cart:before {
    content: "\e902";
    transform: translateY(2px);
    display: inline-block;
}
.icon-heart:before {
    content: "\e903";
    transform: translateY(2px);
    display: inline-block;
}

h1, h2, h3, h4, h5, h6 {
    font: {
        family: $h-font;
        size: 50px;
    }
    color: $main-color;


}
h1 {
    text-transform: uppercase;
}

strong, b {
    font-family: $bold-font;
}
