
.cart-content-header {
    border-bottom: 1px solid #cccccc;
}
.cart-content-row {
    & + .cart-content-row {
        border-top: 1px solid #cccccc;
    }
}

.cart-contents {
    .delete-link {
        color: #ccc;
        text-decoration: none;
        &:hover {
            color: $main-color;
        }
    }
}

.cart-totals {
    .total-amount {
        border:  1px solid #cccccc;
        background-color: #f9f9f9;
        padding: {
            top: 10px;
            bottom: 10px;
        }
        & + .total-amount {
            border-top: none;

        }
    }
}

.proceed-button {
    position: relative;
    z-index: 1;
    a {
        @extend .uk-button;
        @extend .order-btn;
    }
}

section.cart-header {
    padding: {
        top: 60px;
        bottom: 60px;
    }
    position: relative;
    background: {
        image: url("/images/home-intro-bg.jpg");
        size: cover;
        position: center;
    }
    &:before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $dark-bg-color;
        opacity: .8;
    }
    h1 {
        z-index: 2;
        position: relative;
        margin-top: 110px;
        display: inline-block;
        font-size: 48px;
        color: $light-font-color;
        &:after {
            content: "shop";
            z-index: -1;
            position: absolute;
            bottom: 0;
            right: 50px;
            color: $main-color;
            font: {
                family: $hand-font;
                size: 100px;
                weight: normal
            }
        }
    }
}

body.cart-page, body.checkout-page {
    position: relative;
    @include media-breakpoint-up(lg) {
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 50%;
            height: 100%;
            background: {
                image: url("/images/bg-trending-left.png");
                repeat: repeat-y;
            }
        }

        &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            width: 50%;
            height: 100%;
            transform: scaleX(-1);
            background: {
                image: url("/images/bg-trending-left.png");
                repeat: repeat-y;
                position: left;
            }
        }
    }

}

.checkout-page {
.cart-totals .total-amount:last-of-type {
    position: relative;
}

    @include media-breakpoint-down(sm) {
        .btn {
            width: 100%;
            text-align: center;
        }
    }
}

.form-steps {
    list-style-type: none;
    padding-left: 10px;
    li {
        border: 1px solid $pastel-3;

        display: inline-block;
        transform: skew(-24deg);
        margin-right: 5px;
        a, span {
            display: inline-block;
            transform: skew(24deg);
            padding: 10px 30px;
            color: $pastel-3;
        }
        &.enabled, &.current {
                background-color: $pastel-3-accent;
            a, span {
                color: $white;
            }
        }
    }
}

.checkout-header {
    font-size: 30px;
}
