/* MAIN STYLE */

#over-ons-main {

    > div {
        position: relative;
        max-width: 1180px;
        &:before {
            position: absolute;
            content: "";
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            height: 100%;
            width: 80%;
            background-color: #bd9c69;
            z-index: 0;
        }
    }
    p {
        font-family: var(--bebas);
        font-size: 35px;
        line-height: 1.2;
        text-transform: uppercase;
        color: var(--white);
    }
    ul {
       list-style: disc;
        li {
            font-size: 16px;
            line-height: 25px;
            color: var(--white);
        }
    }

}

/* SISTERS STYLE */

#over-ons-main > div {

    /* SANNE */
    > div:nth-child(1) {
        padding-top: 110px;
        > div.col-md-4 {
            position: relative;
            &:before {
                content: 'Sanne';
                position: absolute;
                top: 80%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                -moz-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                -o-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                font-family: var(--bebas);
                font-size: 50px;
                line-height: 1.2;
                text-transform: uppercase;
                color: #bd9c69;
                z-index: 30;
            }
        }
        > div.col-md-8 p {
            max-width: 444px;
            position: relative;
            margin-bottom: 90px;
            &:before {
                position: absolute;
                content: "";
                width: 280px;
                height: 5px;
                background-color: #fff;
                bottom: -33px;
                left: -80px;
            }
        }
    }


    /* FEMKE */
    > div:nth-child(3) {
        padding-top: 110px;
        > div.col-md-4 {
            position: relative;
            &:before {
                content: 'Femke';
                position: absolute;
                top: 80%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                -moz-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                -o-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                font-family: var(--bebas);
                font-size: 50px;
                line-height: 1.2;
                text-transform: uppercase;
                color: #bd9c69;
                z-index: 30;
            }
        }
        > div.col-md-8 p {
            max-width: 444px;
            position: relative;
            margin-bottom: 90px;
            &:before {
                position: absolute;
                content: "";
                width: 280px;
                height: 5px;
                background-color: #fff;
                bottom: -33px;
                left: -80px;
            }
        }
    }
    > div:nth-child(2) {
        padding-top: 110px;
        > div.col-md-4 {
            position: relative;
            &:before {
                content: 'Femke';
                position: absolute;
                top: 80%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                -moz-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                -o-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                font-family: var(--bebas);
                font-size: 50px;
                line-height: 1.2;
                text-transform: uppercase;
                color: #bd9c69;
                z-index: 30;
            }
        }
        > div.col-md-8 p {
            max-width: 444px;
            position: relative;
            margin-bottom: 90px;
            &:before {
                position: absolute;
                content: "";
                width: 280px;
                height: 5px;
                background-color: #fff;
                bottom: -33px;
                right: -45px;
            }
        }
    }
    /* NIENKE */
    > div:nth-child(3) {
        padding-top: 110px;
        > div.col-md-4 {
            position: relative;
            &:before {
                content: 'Nienke';
                position: absolute;
                top: 80%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                -moz-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                -o-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                font-family: var(--bebas);
                font-size: 50px;
                line-height: 1.2;
                text-transform: uppercase;
                color: #bd9c69;
                z-index: 30;
            }
        }
        > div.col-md-8 p {
            max-width: 444px;
            position: relative;
            margin-bottom: 90px;
            &:before {
                position: absolute;
                content: "";
                width: 280px;
                height: 5px;
                background-color: #fff;
                bottom: -33px;
                left: -80px;
            }
        }
    }

}

@include media-breakpoint-up(lg) {
    #over-ons-main > div > div:nth-child(2) > div.col-md-8 ul {
        padding-left: 180px;
    }

    #over-ons-main > div > div:nth-child(2) > div.col-md-8 p {
        text-align: right;
        max-width: 525px;
    }
    #over-ons-main > div > div:nth-child(3) > div.col-md-8 {
        padding-top: 100px;
        padding-left: 95px;
    }
    #over-ons-main > div > div:nth-child(2) > div.col-md-8 {
        padding-left: 190px;
        padding-top: 100px;
    }

    #over-ons-main > div > div:nth-child(1) > div.col-md-8 {
        padding-top: 100px;
        padding-left: 95px;
    }

}


/*************************
 *      OLD CSS CODE     *
 *************************/

@media (max-width: 991px) {
    #over-ons-main > div > div:nth-child(1) > div.col-md-8 {
        padding-top: 30px;
        padding-left: 18vw;
        padding-right: 18vw;
    }

    #over-ons-main > div > div:nth-child(1) > div.col-md-8 p,
    #over-ons-main > div > div:nth-child(2) > div.col-md-8 p,
    #over-ons-main > div > div:nth-child(3) > div.col-md-8 p {
        font-size: 25px;
    }

    #over-ons-main > div > div:nth-child(2) {
        padding-top: 30px;
    }

    #over-ons-main > div > div:nth-child(2) > div.col-md-8 {
        padding-left: 18vw;
        padding-top: 30px;
        padding-right: 18vw;
    }

    //#over-ons-main > div > div:nth-child(2) > div.col-md-8 {
    //    order: 2;-webkit-order: 2;
    //}
    //
    //#over-ons-main > div > div:nth-child(2) > div.col-md-4 {
    //    order: 1;-webkit-order: 1;
    //}

    #over-ons-main > div > div:nth-child(2) > div.col-md-8 ul {
        padding-left: 0px;
    }

    #over-ons-main > div > div:nth-child(2) > div.col-md-8 p::before {
        right: 0px;
        left: -80px;
    }

    #over-ons-main > div > div:nth-child(2) > div.col-md-8 p {
        text-align: left;
    }

    #over-ons-main > div > div:nth-child(3) {
        padding-top: 30px;
    }

    #over-ons-main > div > div:nth-child(3) > div.col-md-8 {
        padding-top: 30px;
        padding-left: 18vw;
        padding-right: 18vw;
    }
}
/*  NOT MOBILE  */

#over-ons-main > div > div:nth-child(2) > div.col-md-4 {position: relative;}
#over-ons-main > div > div:nth-child(2) > div.col-md-4::before {

}

#over-ons-main > div > div:nth-child(2) > div.col-md-8 p {

}

#over-ons-main > div > div:nth-child(2) > div.col-md-8 p::before {


}


#over-ons-main > div > div:nth-child(3) {padding-bottom: 100px;}





#over-ons-main > div > div:nth-child(1) > div.col-md-4 > div:before {
    position: absolute;
    height: 100%;
    width: calc(100% - 30px);
    content: "";
    background: linear-gradient(
            180deg, rgba(255, 255, 255, 0) 55%, rgba(255, 255, 255, .77) 100%);
}
#over-ons-main > div > div:nth-child(2) > div.col-md-4 > div:before {
    position: absolute;
    height: 100%;
    width: calc(100% - 30px);
    content: "";
    background: linear-gradient(
            180deg, rgba(255, 255, 255, 0) 55%, rgba(255, 255, 255, .77) 100%);
}


#over-ons-main > div > div:nth-child(3) > div.col-md-4 > div:before {
    position: absolute;
    height: 100%;
    width: calc(100% - 30px);
    content: "";
    background: linear-gradient(
            180deg, rgba(255, 255, 255, 0) 55%, rgba(255, 255, 255, .77) 100%);
}





    #over-ons-main > div > div:nth-child(2) {
        padding-top: 70px;
    }








    #over-ons-main > div > div:nth-child(3) {
        padding-top: 70px;
    }


