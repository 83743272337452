.site-footer {
    padding: {
        top: 80px;
        bottom: 80px;
    }
    @include media-breakpoint-down(sm) {
        padding: {
            top: 35px;
            bottom: 35px;
        }
    }
}

