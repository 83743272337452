
header {
    position: sticky;
    top:0;
    z-index: 99999;
    background-color: $beige;
}

.mini-cart {
    position: relative;
    a, a.visited {
        color: $main-color;
    }
    .badge-main {
        background-color: $pastel-2;
        position: absolute;
        right: -2px;
        top: -5px;
        color: $black;
        z-index: 999;
    }
}

.search-field input {
    width: 600px;
    max-width: 60vw;

}
.do-search {
    color: $main-color;
    cursor: pointer;
}
